<template>
  <div>
    <div class="headers">
      <!-- <div style="display: none" class="headers"> -->
      <div class="nav">
        <div class="logoBox">
          <img class="logo" src="../assets/home/1.png" alt="" />

          <div class="navlist">
            <el-menu
              :default-active="$store.state.tab"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-menu-item
                :key="index"
                v-for="(item, index) in navList"
                :index="index + ''"
                >{{ item.navItem }}</el-menu-item
              >
            </el-menu>
          </div>
          <div class="search">
            <!-- <img src="../assets/home/2.png" alt="" srcset="" /> -->
          </div>
        </div>
      </div>
    </div>
    <slot></slot>

    <div class="foots" v-if="$store.state.tab != 2">
      <div class="sameDiv footertop">
        <div class="left">
          <div class="inn-l">
            <img src="../assets/home/28.png" alt="" srcset="" />
            <div class="tabName">网站导航</div>
            <div class="tabs1">
              <div :class="{active1:$store.state.tab==index}" @click="navs(item,index)" :key="index" v-for="(item, index) in navList">
                {{ item.navItem }}
              </div>
            </div>
            <!-- <div class="btns">
              <img src="../assets/home/29.png" alt="" srcset="" />400-1234-23
            </div> -->
          </div>
          <div class="inn-r">
            <div class="tilt3">联系我们</div>
            <div class="aa">地址：北京市朝阳区朝阳门外大街19号楼华普大厦6层624室</div>
            <div class="aa">电话：86-10-85861858</div>
            <div>邮箱：info@visiondynamic.tv</div>
          </div>
        </div>
        <div class="right">
          <!-- <div class="us">关注我们</div> -->
          <img class="ewm" src="../assets/header/24.png" alt="" srcset="" />
          <!-- <div class="gzh">官方公众号</div> -->
        </div>
      </div>
    </div>
    <div class="footers" v-if="$store.state.tab != 2">
      <div>
        Copyright @北京维视动力信息技术有限公司All Rights Reserved.版权所有
      </div>
      <div class="same">
          网站备案/许可证号：<a href="https://beian.miit.gov.cn/" target="_blank"
            > 京ICP备2021006873号-1</a
          >
        </div>

        <!-- <div class="same">
          <a
          
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011202003701"
          >
            <img
              class="badge"
              src="../assets/header/hui.png"
              alt="pic"
            />&nbsp;&nbsp;公网安备11011202008888号</a
          >
        </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      cur1:0,
      cur: 0,
      flieList: [],
      // activeIndex: this.$store.state.tab,
      navList: [
        { name: "/", navItem: "首页" },
        { name: "/news", navItem: "新闻资讯" },
        { name: "/product", navItem: "产品信息" },
        { name: "/solution", navItem: "解决方案" },
        { name: "/case", navItem: "应用案例" },
        { name: "/aboutus", navItem: "关于我们" },
        // { name: "/support", navItem: "技术支持" },
      ],
    };
  },
  mounted() {
    console.log(localStorage.getItem("CompanyWebsitcurIndex"));
  },
  methods: {
    navs(item,index){
      this.$store.commit("changeTab", index);
      this.$router.push(item.name);
      ths.cur1 = index
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.$store.commit("changeTab", key);

      this.$router.push(this.navList[key].name);
    },
  },
};
</script>

<style lang="scss" scoped>
// .el-menu--horizontal .el-menu .el-menu-item:last-child {
//   display: none !important;
//   height: 0 !important;
//   background: #000 !important;
// }

.active {
  color: red;
}
.headers {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);

  ::v-deep .el-dropdown-menu__item,
  .el-menu-item {
    padding: 0 !important;
  }
  .el-menu .el-menu-item:first-child {
    width: 34px !important;
  }
  .el-menu .el-menu-item {
    width: 72px;
    text-align: center;
    margin-right: 60px;
  }
  ::v-deep .el-menu {
    background: #171e2c !important;
  }
  ::v-deep .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu--horizontal > .el-menu-item:hover {
    background: none;
    color: #107eff !important;
  }
  ::v-deep .el-menu--horizontal > .el-menu-item {
    height: 45px;
    line-height: 45px;
    font-size: 18px;
  }
  ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
    color: #107eff !important;
    background: #171e2c !important;
  }
  .nav {
    background: #171e2c;
    padding: 0 280px 0 91px;
    height: 71px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    .logoBox {
      width: 100%;
      display: flex;
      align-items: center;
      .logo {
        width: 189px;
        height: 38px;
      }

      .navlist {
        padding-left: 254px;
        flex: 1;
      }
      .search {
        cursor: pointer;
        img {
          margin-top: 4px;
          width: 27px;
          height: 27px;
        }
      }
    }
  }
}

@media screen and (max-width: 1800px) {
  .navlist {
    padding-left: 50px !important;
  }
  .nav {
    padding: 0 80px 0 91px !important;
  }
}

@media screen and (max-width: 1420px) {
  .navlist {
    padding-left: 50px !important;
  }
  .nav {
    padding: 0 80px 0 91px !important;
  }
}
</style>
<style lang="scss" scoped>
.foots {
  position: relative;
  z-index: 3;
  background: #121c30;
  padding: 47px 0 70px;

  .tabName {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 21px;
    margin: 38px 0 9px;
  }
  .tabs1 {
    display: flex;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.61);
    line-height: 19px;
    cursor: pointer;
   
    > div {
      margin-right: 21px;
      &.active1{
      color: #107EFF !important;
    }
    }
   
  }

  .footertop {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: flex-end;

      .inn-l {
        margin-right: 54px;
        img {
          display: flex;
        }
        .btns {
          display: inline-flex;
          align-items: center;
          margin: 41px 0 0;
          padding: 8px 16px;
          background: #107eff;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 19px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 4px;
          }
        }
      }
      .inn-r {
        // border-left: 1px solid rgba(255, 255, 255, 0.16);
        padding-left: 52px;
        font-size: 16px;
font-family: PingFang SC, PingFang SC;
font-weight: 400;
color: rgba(255,255,255,0.61);
.aa{
  margin-bottom: 10px;
}
        .tilt3{
          font-size: 18px;
font-family: PingFang SC, PingFang SC;
font-weight: 500;
color: #FFFFFF;
line-height: 21px;
margin-bottom: 15px;
        }
      }
    }
    .right {
      .ewm {
        display: block;
        width: 108px !important;
        height: 108px !important;
        margin: 20px auto 0;
      }
      .us {
        font-size: 17px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
      }
      .gzh {
        font-size: 17px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #107eff;
        line-height: 20px;
      }
    }
  }

  .foot {
    padding: 56px 97px 30px 80px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    .right {
      line-height: 20px;
      color: rgba(0, 0, 0, 1);
      font-size: 14px;
      text-align: left;
      font-family: PingFangSC-regular;
      div {
        margin-bottom: 12px;
      }
    }
    .left {
      padding-top: 5px;
      display: flex;
      img {
        width: 138px;
        margin-right: 16px;
        height: 53px;
      }
      .conts {
        .name {
          margin-top: 12px;
          font-family: PingFangSC-regular;
          color: rgba(16, 16, 16, 1);
          font-size: 20px;
          margin-bottom: 12px;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }
      }
      .addr {
        line-height: 20px;
        color: rgba(16, 16, 16, 1);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-regular;
      }
    }
  }
}

.footers {
  text-align: center;
  padding: 19px 0;
  background: #0a1221;
  // padding-top: 40px;
  div,
  a {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #62769c;
  }
}
</style>